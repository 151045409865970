import axios from 'axios'
import Token from './utils/token';

const baseURL = '/api/';
let axiosInstance = axios.create({ baseURL: baseURL });
axiosInstance.interceptors.request.use((config) => {
    if (Token.getValue()) {
        config.headers.token = Token.getValue();
    }
    return config;
}, (err) => {
    return Promise.reject(err);
})

export default {
    sendReq: function (url, params = {}, type) {
        return new Promise((resove, reject) => {
            axiosInstance[type](url, params).then(res => {
                resove(res);
            }).catch(err => {
                reject(err);
            })
        })
    }
}